body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ck-toolbar,
.ck-read-only {
  border: 0px !important;
}

.read-only-ck-editor-vbridge .ck-sticky-panel__content {
  display: none !important;
}

.read-only-ck-editor-vbridge {
  font-size: 0.88rem;
}

.read-only-ck-editor-vbridge,
.write-ck-editor-vbridge {
  font-family: "Roboto";
  color: #000;
  line-height: 1.8;
}

#vkartical {
  color: red !important;
}

.ck-content {
  min-height: 10rem !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
  height: 48px !important;
}

.MuiAccordionDetails-root {
  padding: 8px 16px !important;
}
