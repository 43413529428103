.MuiCardHeader-subheader {
  font-size: 10px !important;
}

.MuiCardHeader-title {
  font-size: 14px !important;
}

.name {
  font-weight: 500;
}

.date {
  font-size: 10px !important;
  margin-left: 20px;
  font-weight: 400;
}

.readLessBtn,
.LinesEllipsis-ellipsis {
  border: none;
  margin-top: 3px;
  color: #4179bd;
  background: none;
  cursor: pointer;
}

.readLessBtn {
  font-size: 0.7rem;
}
