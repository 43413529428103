.CircularProgressbar {
  width: 30px;
}

.CircularProgressbar .CircularProgressbar-text {
  dominant-baseline: central;
}

.MuiPopover-paper {
  top: 390;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.MuiAutocomplete-popper {
  font-size: 10px;
}

.MuiInputBase-root input {
  font-size: 0.8rem;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 6px !important;
}
