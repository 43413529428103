.MuiSnackbarContent-root {
  background-image: linear-gradient(#d0d3ee, #edeef8);
  color: black !important;
}

/* .MuiButton-label {
  justify-content: left !important;
} */

a:-webkit-any-link {
  color: black !important;
  cursor: pointer;
  text-decoration: none !important;
}
